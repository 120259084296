import Rest from '@/services/Rest';

/**
 * @typedef {IntegrationService}
 */
export default class IntegrationService extends Rest {
    /**
     * @type {String}
     */
    static resource = '/integrator-credential'
}
