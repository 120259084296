<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="integrações" :menu="menu"/>

     <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-8">
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" placeholder="Procurar" v-model="search">
                                <button type="submit" class="btn btn-info" @click="fetchIntegrations()">Buscar</button>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                            <div class="form-group d-flex">
                                <b-link :to="{name: 'integrationNew'}" class=" ml-auto">
                                    <button type="submit" class="btn btn-dark"><i class="fas fa-street-view mr-2"></i>Novo integrador</button>
                                </b-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div v-for="(item) in integrations" :key="item.id" class="card-list"> 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-2 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                             <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                            <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                        </div>
                                        </div>
                                        <div class="col-8 col-md-7 col-lg-10 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 pad-content-list">
                                                        <h6 class="text-truncate card-body-list-title">{{item.name}}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                            <b-link :to="{name: 'IntegrationEdit', params: {id: item.id}}" class="btn btn-dark rounded-circle btn-circle font-20 card-body-list-cta" >+</b-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
         <div class="col-12">
                    <BasePagination
                        v-if="integrations.length > 0"
                        :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage"
                        @to-page="toPage"
                        @per-page="perPage" />
                </div>

</div>
</template>
<script>

import integrationService from "@/services/resources/IntegrationService";
const service = integrationService.build();

export default {
   data(){
       return{
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'integrações',
                    to: 'Integration'
                }
            ],
            integrations: [],
            search: null,
             pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
       }
   },
   computed:{

   },
   methods:{
       toPage(page) {
            this.pagination.currentPage = page;
            this.fetchClients();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchClients();
        },
       fetchIntegrations(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            if(this.search){
                data['name'] = this.search;
            }

            service
            .search(data)
            .then(resp => {
                this.integrations = resp.data;
                this.pagination.totalPages = resp.last_page;
                this.pagination.itens = resp.total;
            })
            .catch(err => {
            console.log(err)
            })

       },
   },
   mounted(){
       this.fetchIntegrations();
   },
   
}
</script>